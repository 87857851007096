/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import Vue from 'vue';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import VueApexCharts from 'vue-apexcharts';
// import CKEditor from '@ckeditor/ckeditor5-vue';
import {
  UserLoadSite, getUser, ModelFactory, AppAxiosInstance, BehaviourFactory,
  AppModel,
  baseRoutes,
  ControlModelFactory,
} from '@quickdrawltd-org/eden/src/main';
import vClickOutside from 'v-click-outside';
import * as VueThreejs from 'vue-threejs';
import TreeView from 'vue-json-tree-view';

import App from './App.vue';
import awsconfig from './aws-exports';
import Router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

let envPath = '.env';
const mode = process.env.NODE_ENV;
if (mode) {
  if (mode.includes('cd')) {
    envPath = `.env.${mode}`;
  }
}

console.log(`envPath: ${envPath}`);

require('dotenv').config({ path: envPath });


const ROOT_ADDRESS = process.env.VUE_APP_ROOT_ADDESS;
const GOOGLE_LOG_IN = process.env.VUE_APP_GOOGLE_URL;
const LOG_OUT_URL = process.env.VUE_APP_LOG_OUT_URL;
const logOutUrl = `${ROOT_ADDRESS}${LOG_OUT_URL}`;
const googleLogIn = `${ROOT_ADDRESS}${GOOGLE_LOG_IN}`;

const aws_cognito_identity_pool_id = process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID;
const aws_cognito_region = process.env.VUE_APP_AWS_COGNITO_REGION;
const aws_project_region = process.env.VUE_APP_AWS_PROJECT_REGION;
const aws_user_pools_id = process.env.VUE_APP_AWS_USER_POOLS_ID;
const aws_user_pools_web_client_id = process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID;

console.log(JSON.stringify(awsconfig));
const y = awsconfig;
// FAKE IT TILL YOU MAKE IT
y.aws_cognito_identity_pool_id = aws_cognito_identity_pool_id;
y.aws_cognito_region = aws_cognito_region;
y.aws_project_region = aws_project_region;
y.aws_user_pools_id = aws_user_pools_id;
y.aws_user_pools_web_client_id = aws_user_pools_web_client_id;
y.oauth.redirectSignOut = logOutUrl;
y.oauth.redirectSignIn = googleLogIn;
console.log(JSON.stringify(y));
Amplify.configure(y);

Vue.use(AmplifyPlugin, AmplifyModules);

Vue.use(VueApexCharts);
Vue.use(TreeView);
Vue.component('apexchart', VueApexCharts);
Vue.use(vClickOutside);
Vue.use(VueThreejs);
Vue.config.productionTip = false;

const AppRouter = new Router();
const apiEndPoint = process.env.VUE_APP_API_ENDPOINT;
console.log(apiEndPoint);
AppAxiosInstance.configure(Router.router, apiEndPoint);

window.onerror = function (msg, url, line, col, error) {
  console.log(`window.onerror:
  ${error.toString()}`);
  AppAxiosInstance.getInstance().post('/automated_bug_reporting', {
    err: error.toString(),
    route: Router.router.currentRoute.path,
    info: msg,
  }).catch((restErr) => {
    console.log(`err:::automated_bug_reporting::${JSON.stringify(restErr)}`);
  });
};

const getCircularReplacer = (): any => {
  const seen = new WeakSet();
  return (key, value) => {
    console.log(`key: ${key}`);
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

Vue.config.errorHandler = (err, vm, info) => {
  console.log(`vue.errorHandler:
  err: ${err.toString()}
  info: ${info.toString()}
  vm: ${vm.$data}`);

  AppAxiosInstance.getInstance().post('/automated_bug_reporting', {
    err: err.toString(),
    route: Router.router.currentRoute.path,
    info,
  }).catch((restErr) => {
    console.log(`err:::automated_bug_reporting::${JSON.stringify(restErr)}`);
  });
};

Vue.config.warnHandler = (err, vm, info) => {
  console.log(`vue.warnHandler:
  err: ${err.toString()}
  info: ${info.toString()}`);
  AppAxiosInstance.getInstance().post('/automated_bug_reporting', {
    err: err.toString(),
    route: Router.router.currentRoute.path,
    info,
  }).catch((restErr) => {
    console.log(`err:::automated_bug_reporting::${JSON.stringify(restErr)}`);
  });
};

baseRoutes.loadBaseRoutes().then((results) => {
  AppModel.configure(store);
  baseRoutes.loadSiteSettings().then(() => {
    AppRouter.setBaseRoutes(results);
    // eslint-disable-next-line no-new
    new ModelFactory(Router.router);
    // eslint-disable-next-line no-new
    new BehaviourFactory(Router.router);
    // eslint-disable-next-line no-new
    new ControlModelFactory(Router.router);
    // eslint-disable-next-line prefer-destructuring
    const router = Router.router;
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount('#app');

    getUser().then((user) => {
      if (user) {
        const userLoadSite = new UserLoadSite(Router.router);
        console.log(Router.router.currentRoute.path);
        let redirect = '';
        if (Router.router.currentRoute.path === '/') {
          redirect = '/home';
        } else {
          redirect = Router.router.currentRoute.path;
        }
        userLoadSite.Load(user, redirect);
      }
    });
  });
});
