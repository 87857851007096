/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import Vue from 'vue';
import Vuex from 'vuex';
import { IModule, AppAxiosInstance } from '@quickdrawltd-org/eden/src/main';

function findRoute(baseModule: IModule, routeName: string): IModule {
  baseModule.modules.forEach((module) => {
    if (module.routeName === routeName) {
      return module;
    }
    return findRoute(module, routeName);
  });
  if (baseModule.routeName === routeName) {
    return baseModule;
  }
  return null;
}

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    siteDefinition: {},
    siteSettings: {},
    userProfile: {},
    modules: [],
    siteBannerId: '',
    siteBanner: '',
    siteLogoId: '',
    siteLogo: '',
    siteTitle: '',
    siteFooterText: '',
    searchSettings: {},
  },
  mutations: {
    setUserProfile(state, profile) {
      state.userProfile = profile;
    },
    setSiteSettings(state, siteSettings) {
      state.siteSettings = siteSettings;
      state.siteTitle = (siteSettings as any).site_title;
      state.siteBannerId = (siteSettings as any).banner_image;
      state.siteFooterText = (siteSettings as any).footer_text;
      state.siteLogoId = (siteSettings as any).site_logo;
      state.searchSettings = (siteSettings as any).searchSettings;
    },
    setSiteBannerId(state, bannerId) {
      state.siteBannerId = bannerId;
    },
    setSiteDefinition(state, site) {
      state.siteDefinition = site;
      if (state.siteDefinition !== {}) {
        const siteMods = [];
        Object.keys(state.siteDefinition).forEach((thing) => {
          if (state.siteDefinition[thing].some(x => x.display)) {
            siteMods.push(thing);
          }
        });
        state.modules = siteMods;
      } else {
        state.modules = [];
      }
    },
  },
  actions: {
    getBanner({ commit, state }) {
      const { siteBannerId } = state;
      AppAxiosInstance.getInstance().get(`/file/${siteBannerId}`).then(data => data.data);
    },
    setUserProfile: ({ commit, state }, newValue) => {
      commit('setUserProfile', newValue);
      return state.userProfile;
    },
    setSiteSettings: ({ commit, state }, newValue) => {
      commit('setSiteSettings', newValue);
      return state.siteSettings;
    },
  },
  modules: {
  },
  getters: {
    getSubModules: state => id => state.siteDefinition[id],
    getUserProfile: state => state.userProfile,
    getSiteDefinition: state => state.siteDefinition,
    getModules: state => state.modules,
    getSiteSettings: state => state.siteSettings,
    getSiteName: state => state.siteTitle,
    getSiteBannerId: state => state.siteBannerId,
    getSiteBanner: state => state.siteBanner,
    getSiteLogo: state => state.siteLogo,
    getSiteFooterText: state => state.siteFooterText,
    getSearchSettings: state => state.searchSettings,
  },
});
