
import Vue from 'vue';
import Component from 'vue-class-component';
import { RouteConfig } from 'vue-router';
import { AmplifyEventBus, components } from 'aws-amplify-vue';
import {
  AppAxiosInstance,
  componentLookup,
  ProfileDropDownScreen,
  UserLoadSite,
  BeforeNavigate,
  AppError,
  getUser,
  GenericView,
  IModule,
  SearchAutoComplete,
  NavigationControl,
} from '@quickdrawltd-org/eden/src/main';
// import { ViewDefault } from './interfaces/IView';
import { mapGetters, mapState } from 'vuex';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import SiteModel from './partBuilder/siteBuilder';
import Router from './router';

@Component({
  computed: mapGetters(['getSiteName', 'getSiteBanner', 'getSiteLogo', 'getSiteFooterText']),
  components: {
    components,
    ProfileDropDownScreen,
    GenericView,
    BeforeNavigate,
    AppError,
    SearchAutoComplete,
    NavigationControl,
  },
})
export default class App extends Vue {
  name: string = 'App';

  getSiteLogo: string;

  minimised = false;

  activeTab = null;

  toggleFullscreen() {
    this.minimised = !this.minimised;
  }

  // eslint-disable-next-line camelcase
  getSiteName: string;

  getSiteFooterText: string;

  @Watch('getSiteBanner')
  onSiteLogoChanged(val: any) {
    this.logoStr = window.URL.createObjectURL(val);
  }

  @Watch('getSiteLogo')
  onSiteBannerChanged(val: any) {
    this.bannerStr = window.URL.createObjectURL(val);
  }

  @Watch('getSiteName')
  onSiteTitleChanged(val: any) {
    this.title = val;
    document.title = this.title;
  }

  logoStr: string = '';

  bannerStr: string = '';

  contain: boolean = true;

  // siteName: string = '';

  signOutVisible: boolean = false;

  moduleOptions = {};

  appAxios: AppAxiosInstance = AppAxiosInstance.getInstance();

  userLoadSite: UserLoadSite;

  siteModel: {};

  appState: string = '';

  title: string = 'test';

  showModuleOptions: boolean = true;

  loadLandingPage(module: any) {
    const options = this.$store.getters.getSubModules(module);
    const landingPage = options.find(pages => pages.isLandingPage === true);
    this.$router.push(landingPage.route);
  }

  toggleModules() {
    this.showModuleOptions = !this.showModuleOptions;
  }

  loadModuleOptions(moduleNumber: number) {
    const moduleName = this.$store.getters.getModules[moduleNumber];
    const tempModuleOptions = this.$store.getters.getSubModules(moduleName) || [];
    const allowedTempModules = tempModuleOptions.filter(x => x.display === true);
    const moduleMap = {};
    allowedTempModules.forEach((atm) => {
      if (!moduleMap[atm.group]) {
        moduleMap[atm.group] = [];
      }
      moduleMap[atm.group].push(atm);
    });
    this.moduleOptions = moduleMap;
  }

  get siteBanner() {
    const siteBanner = this.$store.getters.getSiteBanner;
    return `data:image/jpeg;base64,${siteBanner}`;
  }

  get searchSettings() {
    const searchSettingsControl = this.$store.getters.getSearchSettings;
    return searchSettingsControl;
  }

  get siteModules() {
    const modules = this.$store.getters.getModules;
    // eslint-disable-next-line no-underscore-dangle
    const currentModule = this.$root.$route;

    if (currentModule.matched.length === 0) {
      console.log(currentModule.matched[0]);
      // we did it
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const moduleDefinition of Object.keys(this.$store.getters.getSiteDefinition)) {
        if (this.$store.getters.getSiteDefinition[moduleDefinition]
          .find(x => x.route === currentModule.path)) {
          this.activeTab = modules.indexOf(moduleDefinition);
          this.loadModuleOptions(this.activeTab);
          break;
        }
      }
      const x = modules; //  .find
      console.log('found it');
    }

    if (modules === null) {
      this.moduleOptions = [];
    }
    return modules;
  }

  update = mapState(['siteBannerId']);

  get haveUser() {
    if (Object.keys(this.$store.getters.getUserProfile).length === 0) {
      this.moduleOptions = {};
      return null;
    }
    return this.$store.getters.getUserProfile;
  }

  created() {
    this.name = 'bob';
  }

  loadPage(routeName: string) {
    let subModule = null;
    // let module = null;
    // eslint-disable-next-line no-restricted-syntax
    for (const group of Object.keys(this.moduleOptions)) {
      if (this.moduleOptions[group].find(x => x.routeName === routeName)) {
        subModule = this.moduleOptions[group].find(x => x.routeName === routeName);
        break;
      }
    }
    if (subModule) {
      console.log(`i did the dirty work ${routeName}`);
      this.$router.push(subModule.route);
    }
    // const subModule = this.moduleOptions.find(x => x.routeName === routeName);
  }

  stateChanged(info) {
    if (info === 'signedIn') {
      this.signOutVisible = true;
    }
  }

  constructor() {
    super();
    AmplifyEventBus.$on('authState', this.stateChanged);
  }
}
