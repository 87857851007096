import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { AmplifyEventBus } from 'aws-amplify-vue';
import {
  AppAxiosInstance, getUser, baseRoutes,
} from '@quickdrawltd-org/eden/src/main';
import SiteModel from '../partBuilder/siteBuilder';
// import baseRoutes from './baseRoutes';

Vue.use(VueRouter);

export default class Router {
  AppAxios: AppAxiosInstance;

  constructor() {
    this.AppAxios = AppAxiosInstance.getInstance();
    // this.baseRoutes().then(async (brs) => {
    Router.router = new VueRouter({
      mode: 'history',
      base: process.env.BASE_URL,
      routes: [],
    });

    Router.router.beforeResolve(async (to, from, next) => {
      const user = await getUser();
      if (!user) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
          return next({
            path: '/signIn',
          });
        }
      }
      return next();
    });
    // });
  }


  // eslint-disable-next-line class-methods-use-this
  public setBaseRoutes(siteBaseRoutes: RouteConfig[]) {
    Router.router.addRoutes(siteBaseRoutes);
  }

  public static router: VueRouter;

  public baseRoutes = (): Promise<any> => new Promise<any>((res, rej) => {
    baseRoutes.loadBaseRoutes().then(results => res(results));
  })

  // public routes: RouteConfig[] = baseRoutes.getRoutes(Router.router);

  siteModel: SiteModel;

  public async initizalise(): Promise<void> {
    this.siteModel = new SiteModel(Router.router, this.AppAxios);
    AmplifyEventBus.$on('authState', async (state) => {
      const pushPathes = {
        forgotPassword: () => {
          Router.router.push({ path: '/forgotpassword' });
        },
        signedOut: () => {
          Router.router.push({ path: '/signIn' });
        },
        signUp: () => {
          Router.router.push({ path: '/signUp' });
        },
        confirmSignUp: () => {
          Router.router.push({ path: '/signUpConfirm' });
        },
        signIn: () => {
          Router.router.push({ path: '/signIn' });
        },
        signedIn: () => {
          Router.router.push({ name: 'home' });
        },
      };
      if (typeof pushPathes[state] === 'function') {
        pushPathes[state]();
      }
    });
  }
}
