import Vue from 'vue';
import Vuetify from 'vuetify/lib';

const colourSchemeDefined = process.env.VUE_APP_COLOUR_SCHEME_DEFINED;
const isCSD = (colourSchemeDefined === 'true');

let primary = '#1976D2';
let secondary = '#424242';
let accent = '#82B1FF';
let error = '#FF5252';
let info = '#2196F3';
let success = '#4CAF50';
let warning = '#FFC107';

if (isCSD) {
  primary = process.env.VUE_APP_PRIMARY;
  secondary = process.env.VUE_APP_SECONDARY;
  accent = process.env.VUE_APP_ACCENT;
  error = process.env.VUE_APP_ERROR;
  info = process.env.VUE_APP_INFO;
  success = process.env.VUE_APP_SUCCESS;
  warning = process.env.VUE_APP_WARNING;
}

const colourScheme = {
  primary,
  secondary,
  accent,
  error,
  info,
  success,
  warning,
};
Vue.use(Vuetify, {
  theme: colourScheme,
});

export default new Vuetify();
