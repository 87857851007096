import VueRouter, { RouteConfig } from 'vue-router';
import { IModule, GenericView, AppAxiosInstance } from '@quickdrawltd-org/eden/src/main';
// import store from '@/store';

export default class SiteModel {
    public site: IModule[];

    public selectedModule: IModule;

    private router: VueRouter;

    private AppAxios: AppAxiosInstance;

    constructor(router: VueRouter,
      axiosInstance: AppAxiosInstance) {
      this.router = router;
      this.AppAxios = axiosInstance;
    }

    public setSite(site: IModule[]) {
      this.site = site;
      this.buildRoutes(this.site[1], []);
      // eslint-disable-next-line
      this.selectedModule = this.site[0];
    }

    public findRoute(baseModule: IModule, routeName: string): IModule {
      baseModule.modules.forEach((module) => {
        if (module.routeName === routeName) {
          return module;
        }
        return this.findRoute(module, routeName);
      });
      return null;
    }

    public async buildRoutes(module: IModule, routeConfigs: RouteConfig[]): Promise<void> {
      if (!module) {
        await this.loadSite().then(() => {
          if (this.site) {
            this.createRoutes(this.site[1], []);
          }
        });
      }
      this.createRoutes(module, []);
    }

    private createRoutes(module: IModule, routeConfigs: RouteConfig[]): void {
      module.modules.forEach((element: any) => {
        this.buildRoutes(element, routeConfigs);
      });

      routeConfigs.push({
        name: module.routeName,
        path: module.route,
        component: GenericView,
        props: true,
        meta: { requiresAuth: true },
      });

      this.router.addRoutes(routeConfigs);
    }

    private loadSite(): Promise<void> {
      return new Promise((res, rej) => {
        AppAxiosInstance.Instance.get<any>('/view/5dcc0a12bdf6779ba4ff0c9f',
        // eslint-disable-next-line
              ).then((res) => {
          // store.state.siteDefinition = res.data.module;
          // eslint-disable-next-line
          this.setSite(res.data.modules);
        });
      });
    }
}
